import {
  CalendarIcon,
  GlobeIcon,
  LenderIcon,
  LinkedInOutlinedIcon,
  MapPinIcon,
  MoneyBagIcon,
} from '@gain/components/icons'
import { Lender, LenderListItem } from '@gain/rpc/app-model'
import { formatCountryAndCity } from '@gain/utils/countries'
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'

import Card, { CardContent, CardHeader } from '../../../common/card/card'
import ListItemIconValue from '../../../common/list/list-item-icon-value'
import ListItemIconValueLink from '../../../common/list/list-item-icon-value-link'
import FinancialValue from '../../../features/financial/financial-value'

interface OrganisationCardProps {
  lender: Lender
  lenderListItem: LenderListItem
}

export default function OrganisationCard({ lender, lenderListItem }: OrganisationCardProps) {
  const primaryUrl = lender.urls.find((url) => url.isPrimary)

  return (
    <Card fullHeight>
      <CardHeader title={'Organisation'} />
      <CardContent>
        <List
          dense
          disablePadding>
          {lender.operationalHqCountryCode && (
            <ListItemIconValue
              icon={MapPinIcon}
              tooltip={'Operational HQ'}>
              {formatCountryAndCity(lender.operationalHqCountryCode, lender.operationalHqCity)}
            </ListItemIconValue>
          )}

          {lender.yearFounded && (
            <ListItemIconValue
              icon={CalendarIcon}
              tooltip={'Year founded'}>
              {lender.yearFounded}
            </ListItemIconValue>
          )}

          {lenderListItem.creditMedianDebtQuantumEur && (
            <ListItemIconValue icon={LenderIcon}>
              <Stack
                direction={'row'}
                gap={1}
                justifyContent={'space-between'}>
                <span>Median debt quantum</span>
                <FinancialValue amount={lenderListItem.creditMedianDebtQuantumEur} />
              </Stack>
            </ListItemIconValue>
          )}

          {lenderListItem.assetMedianEbitdaEur && (
            <ListItemIconValue icon={MoneyBagIcon}>
              <Stack
                direction={'row'}
                gap={1}
                justifyContent={'space-between'}>
                <span>Median company EBITDA</span>
                <FinancialValue amount={lenderListItem.assetMedianEbitdaEur} />
              </Stack>
            </ListItemIconValue>
          )}

          {primaryUrl && (
            <ListItemIconValue icon={GlobeIcon}>
              <ListItemIconValueLink url={primaryUrl.url} />
            </ListItemIconValue>
          )}

          {lender.linkedInExternalId && (
            <ListItemIconValue icon={LinkedInOutlinedIcon}>
              <ListItemIconValueLink
                url={`https://linkedin.com/company/${lender.linkedInExternalId}`}
              />
            </ListItemIconValue>
          )}
        </List>
      </CardContent>
    </Card>
  )
}
