import { CreditListItem, Lender } from '@gain/rpc/app-model'
import { CREDIT_TYPE_OPTIONS, getLabelFromOption } from '@gain/rpc/shared-model'
import { useOpenLink } from '@gain/utils/router'
import { MouseEvent } from 'react'

import Card, { CardContentEmpty, CardHeader } from '../../../common/card/card'
import { chartColorSetGrey } from '../../../common/chart/chart-colors'
import TableValueDistribution, {
  TableValueDistributionConfig,
  useValueDistributionRows,
  ValueDistributionRow,
} from '../../../common/table-value-distribution'
import { useFlattenedDistributionRows } from '../../../common/table-value-distribution/table-value-distribution-utils'
import {
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../../features/filter/filter-bar'
import { generateLenderPagePath, LENDER_CREDITS_PATH } from '../lender-path'
import { MIN_VALUE_DISTRIBUTION_CARD_HEIGHT } from './route-summary-utils'

const CREDITS_PER_TYPE_CONFIG: TableValueDistributionConfig<CreditListItem> = {
  getValue: (credit) => credit.type,
  formatValue: (value) => getLabelFromOption(CREDIT_TYPE_OPTIONS, value) || value,
  colorSet: chartColorSetGrey.slice().reverse(),
}

interface CreditsPerTypeCardProps {
  lender: Lender
  credits: CreditListItem[]
}

export default function CreditsPerTypeCard({ lender, credits }: CreditsPerTypeCardProps) {
  const openLink = useOpenLink()
  const rows = useValueDistributionRows(credits, CREDITS_PER_TYPE_CONFIG)
  const flattenedRows = useFlattenedDistributionRows(rows)
  const creditsTabPath = generateLenderPagePath(lender.id, lender.name, LENDER_CREDITS_PATH)

  const handleRowClick = (row: ValueDistributionRow, event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    const types = row.values ? row.values.map((item) => item.type) : row.type
    const path = [
      creditsTabPath,
      filterModelToQueryString<CreditListItem, 'type'>([
        filterValueGroup(filterValueItem('type', types)),
      ]),
    ].join('?')

    openLink(path, event)
  }

  return (
    <Card
      href={flattenedRows.length > 0 ? creditsTabPath : undefined}
      sx={{ minHeight: MIN_VALUE_DISTRIBUTION_CARD_HEIGHT }}
      fullHeight>
      <CardHeader title={'Credits per type'} />
      {flattenedRows.length === 0 ? (
        <CardContentEmpty message={'No data available'} />
      ) : (
        <TableValueDistribution
          itemLabel={'Credit type'}
          onRowClick={handleRowClick}
          rows={flattenedRows}
          shareLabel={'Credits'}
          disableExpand
        />
      )}
    </Card>
  )
}
