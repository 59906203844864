import { CreditListItem } from '@gain/rpc/app-model'
import {
  CREDIT_TYPE_AND_SUBTYPE_VALUES,
  CREDIT_TYPE_OPTIONS,
  CreditType,
  getLabelFromOption,
} from '@gain/rpc/shared-model'
import Chip from '@mui/material/Chip'

import { useCheckboxListFilterToggle } from '../../filter/filter-bar/filter/filter-checkbox/use-checkbox-list-filter-toggle'

function getTypeAndSubtypeValuesForType(type: CreditType) {
  return CREDIT_TYPE_AND_SUBTYPE_VALUES.filter((value) => {
    return type === value || value.startsWith(`${type}/`)
  })
}

interface CreditTypeColumnProps {
  type: CreditType
}

export default function CreditTypeColumn({ type }: CreditTypeColumnProps) {
  const subtypes = getTypeAndSubtypeValuesForType(type)
  const [active, handleClick] = useCheckboxListFilterToggle<
    string,
    CreditListItem,
    'typeAndSubtype'
  >(subtypes, 'typeAndSubtype')

  return (
    <Chip
      color={active.includes(type) ? 'info' : undefined}
      label={getLabelFromOption(CREDIT_TYPE_OPTIONS, type, '-')}
      onClick={handleClick(subtypes)}
    />
  )
}
