import { CreditListItem, Lender, LenderInvestorListItem } from '@gain/rpc/app-model'
import { listSort, listSortArray } from '@gain/rpc/utils'
import { formatMonthYear } from '@gain/utils/date'
import { encodeSortQueryParam } from '@gain/utils/sort'

import Card, { CardContentEmpty, CardHeader } from '../../../common/card/card'
import ContentLink from '../../../common/content-link'
import Table, { createTableColumns } from '../../../common/table'
import CompaniesCell from '../../../features/asset/companies-cell'
import {
  autocompleteIncludeFilterValue,
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../../features/filter/filter-bar'
import InvestorContentLink from '../../../features/investor/investor-content-link'
import { generateLenderPagePath, LENDER_CREDITS_PATH, LENDER_INVESTORS_PATH } from '../lender-path'
import { MIN_TABLE_CARD_HEIGHT } from './route-summary-utils'

function createColumns(lender: Lender) {
  return createTableColumns<LenderInvestorListItem>(
    {
      field: 'investorName',
      headerName: 'Company name',
      width: 196,
      renderCell: ({ row }) => (
        <InvestorContentLink
          id={row.investorId}
          logoFileUrl={row.investorLogoFileUrl}
          name={row.investorName}
        />
      ),
    },
    {
      headerName: 'Credits',
      field: 'creditCount',
      width: 102,
      align: 'right',
      renderCell: ({ row }) => {
        const href = [
          generateLenderPagePath(lender.id, lender.name, LENDER_CREDITS_PATH),
          filterModelToQueryString<CreditListItem, 'dealBuyerInvestorIds'>([
            filterValueGroup(
              filterValueItem(
                'dealBuyerInvestorIds',
                autocompleteIncludeFilterValue([row.investorId])
              )
            ),
          ]),
        ].join('?')

        return (
          <ContentLink
            href={href}
            label={row.creditCount.toString()}
          />
        )
      },
    },
    {
      headerName: 'Companies',
      field: 'linkedAssets',
      width: 144,
      renderCell: ({ value }) => <CompaniesCell assets={value} />,
    },
    {
      headerName: 'Latest deal',
      field: 'latestDealYear',
      width: 108,
      align: 'right',
      valueFormatter: ({ row }) => formatMonthYear(row.latestDealMonth, row.latestDealYear),
    }
  )
}
export const KEY_INVESTORS_SORT = listSortArray<LenderInvestorListItem>(
  listSort('creditCount', 'desc')
)

interface KeyInvestorsCardProps {
  lender: Lender
  investors: LenderInvestorListItem[]
}

export default function KeyInvestorsCard({ lender, investors }: KeyInvestorsCardProps) {
  const path = [
    generateLenderPagePath(lender.id, lender.name, LENDER_INVESTORS_PATH),
    `sort=${encodeSortQueryParam<LenderInvestorListItem>(KEY_INVESTORS_SORT)}`,
  ].join('?')

  return (
    <Card
      href={investors.length > 0 ? path : undefined}
      sx={{ minHeight: MIN_TABLE_CARD_HEIGHT }}
      fullHeight>
      <CardHeader title={'Key investors'} />
      {investors.length === 0 && <CardContentEmpty message={'No data available'} />}
      {investors.length > 0 && (
        <Table
          columns={createColumns(lender)}
          rows={investors}
          dense
          disablePaddingBottomOnLastRow
          disableStickyHeader
        />
      )}
    </Card>
  )
}
