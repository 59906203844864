import Head from '@gain/modules/head'
import { CreditListItem, Lender, LenderInvestorListItem, LenderListItem } from '@gain/rpc/app-model'
import Grid from '@mui/material/Grid2'

import CreditCountsCard from './credit-counts-card/credit-counts-card'
import CreditsPerRegionCard from './credits-per-region-card'
import CreditsPerSectorCard from './credits-per-sector-card'
import CreditsPerTypeCard from './credits-per-type-card'
import KeyInvestorsCard from './key-investors-card'
import LargestCreditsCard from './largest-credits-card'
import OrganisationCard from './organisation-card'

interface RouteSummaryProps {
  lender: Lender
  lenderListItem: LenderListItem
  credits: CreditListItem[]
  keyInvestors: LenderInvestorListItem[]
}

export default function RouteSummary({
  lender,
  lenderListItem,
  credits,
  keyInvestors,
}: RouteSummaryProps) {
  return (
    <>
      <Head>
        <title>{lender.name} - Summary</title>
      </Head>
      <Grid
        spacing={2}
        sx={{ alignSelf: 'stretch' }}
        container>
        <Grid size={{ xs: 12, md: 4 }}>
          <OrganisationCard
            lender={lender}
            lenderListItem={lenderListItem}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <CreditCountsCard
            lender={lender}
            matured={false}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <CreditCountsCard
            lender={lender}
            matured
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <CreditsPerSectorCard
            credits={credits}
            lender={lender}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <CreditsPerRegionCard
            credits={credits}
            lender={lender}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <CreditsPerTypeCard
            credits={credits}
            lender={lender}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <LargestCreditsCard
            credits={credits}
            lender={lender}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 8 }}>
          <KeyInvestorsCard
            investors={keyInvestors}
            lender={lender}
          />
        </Grid>
      </Grid>
    </>
  )
}
