import { useListLenderAssetsInfinite } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { CreditListItem, Lender, LenderAssetListItem } from '@gain/rpc/app-model'
import { formatMonthYear } from '@gain/utils/date'
import { formatBps } from '@gain/utils/number'
import Box from '@mui/material/Box'
import { useState } from 'react'

import Card, { CardHeader } from '../../../common/card/card'
import ContentLink from '../../../common/content-link'
import VirtualTable, { createVirtualTableColumns, VirtualSort } from '../../../common/virtual-table'
import AssetContentLink from '../../../features/asset/asset-content-link'
import AssetFte from '../../../features/asset/asset-fte'
import {
  autocompleteIncludeFilterValue,
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../../features/filter/filter-bar'
import { generateLenderPagePath, LENDER_CREDITS_PATH } from '../lender-path'

function createColumns(lender: Lender) {
  return createVirtualTableColumns<LenderAssetListItem>(
    {
      field: 'assetName',
      headerName: 'Company name',
      width: 196,
      renderCell: ({ row }) => (
        <AssetContentLink
          id={row.assetId}
          logoFileUrl={row.assetLogoFileUrl}
          name={row.assetName}
        />
      ),
    },
    {
      headerName: 'Business description',
      field: 'assetDescription',
      width: 252,
      flex: 1,
      valueFormatter: ({ value }) => value || '-',
    },
    {
      field: 'assetFte',
      headerName: 'FTEs',
      width: 104,
      align: 'right',
      renderCell: ({ row }) => (
        <AssetFte
          fte={row.assetFte}
          range={row.assetFteRange}
          year={row.assetFteYear}
        />
      ),
    },
    {
      headerName: 'Credits',
      field: 'assetCreditCount',
      width: 102,
      align: 'right',
      renderCell: ({ row, value }) => {
        const href = [
          generateLenderPagePath(lender.id, lender.name, LENDER_CREDITS_PATH),
          filterModelToQueryString<CreditListItem, 'assetId'>([
            filterValueGroup(
              filterValueItem('assetId', autocompleteIncludeFilterValue([row.assetId]))
            ),
          ]),
        ].join('?')

        return (
          <ContentLink
            href={href}
            label={value.toString()}
          />
        )
      },
    },
    {
      headerName: 'Avg. coupon',
      field: 'creditAvgCouponBps',
      width: 112,
      align: 'right',
      valueFormatter: ({ value }) => formatBps(value),
    },
    {
      headerName: 'Latest deal',
      field: 'creditMostRecentDealYear',
      width: 120,
      align: 'left',
      sortFields: ['creditMostRecentDealYear', 'creditMostRecentDealMonth'],
      valueFormatter: ({ row }) =>
        formatMonthYear(row.creditMostRecentDealMonth, row.creditMostRecentDealYear),
    }
  )
}

interface RouteCompaniesProps {
  lender: Lender
}

export default function RouteCompanies({ lender }: RouteCompaniesProps) {
  const [sort, setSort] = useState<VirtualSort<LenderAssetListItem>[]>([
    {
      field: 'assetName',
      direction: 'asc',
    },
  ])

  const swrLenderAssetsInfinite = useListLenderAssetsInfinite({
    sort: sort,
    lenderId: lender.id,
    limit: 50,
  })

  return (
    <>
      <Head>
        <title>{lender.name} - Companies</title>
      </Head>

      <Card sx={{ alignSelf: 'stretch', height: '100%', pb: 0 }}>
        <CardHeader title={'Companies'} />
        <Box sx={{ position: 'relative', height: '100%' }}>
          <Box sx={{ position: 'absolute', inset: 0, display: 'flex' }}>
            <VirtualTable
              columns={createColumns(lender)}
              loadingInitial={swrLenderAssetsInfinite.isLoadingInitial}
              loadingMore={swrLenderAssetsInfinite.isLoadingMore}
              onLoadMore={swrLenderAssetsInfinite.fetchMore}
              onSort={setSort}
              RowComponentProps={{
                hover: false,
              }}
              rows={swrLenderAssetsInfinite.data?.flatMap((page) => page.items) || []}
              sort={sort}
              totalSize={swrLenderAssetsInfinite.data?.[0]?.counts.filtered}
              variant={'inline'}
            />
          </Box>
        </Box>
      </Card>
    </>
  )
}
