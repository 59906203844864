import { useCreditList, useDealList } from '@gain/api/app/hooks'
import { CreditListItem, DealListItem } from '@gain/rpc/app-model'
import { ListSort } from '@gain/rpc/list-model'
import { listFilter, listFilters, listSort, listSortArray } from '@gain/rpc/utils'
import { compareDealListItemByDateDesc } from '@gain/utils/deal'
import { isProduction } from '@gain/utils/environment'
import { useIsXs } from '@gain/utils/responsive'
import { useMemo, useState } from 'react'
import { generatePath } from 'react-router-dom'

import { VirtualSort } from '../../../common/virtual-table'
import {
  autocompleteIncludeFilterValue,
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../../features/filter/filter-bar'
import { CREDITS_LIST_PATH } from '../../utils'

const dealSort: ListSort<DealListItem>[] = [
  listSort('announcementDateYear', 'desc'),
  listSort('announcementDateMonth', 'desc'),
]

export function useAssetDealHistoryDeals(assetId: number, limit?: number) {
  return useDealList({
    filter: [listFilter('linkedAssetId', '=', assetId)],
    sort: dealSort,
    limit,
  })
}

export function useAcquisitionsAndDivestmentsDeals(assetId: number, limit?: number) {
  const sellerDeals = useDealList({
    filter: [listFilter<DealListItem>('sellerAssetIds', '=', assetId)],
    sort: dealSort,
    limit,
  })
  const buyerDeals = useDealList({
    filter: [listFilter<DealListItem>('buyerAssetIds', '=', assetId)],
    sort: dealSort,
    limit,
  })
  return useMemo(() => {
    return {
      loading: sellerDeals.loading || buyerDeals.loading,
      error: sellerDeals.error || buyerDeals.error,
      data: [...sellerDeals.data.items, ...buyerDeals.data.items].sort(
        compareDealListItemByDateDesc
      ),
    }
  }, [
    sellerDeals.loading,
    sellerDeals.error,
    sellerDeals.data.items,
    buyerDeals.loading,
    buyerDeals.error,
    buyerDeals.data.items,
  ])
}

export function useCreditFacilities(id: number) {
  const isXs = useIsXs()
  const [sort, setSort] = useState<VirtualSort<CreditListItem>[]>(
    listSortArray(listSort('debtQuantumEur', 'desc'), listSort('issuedAt', 'desc'))
  )
  const swrCredits = useCreditList({
    filter: listFilters(listFilter('assetId', '=', id)),
    sort,
    limit: 6,
  })

  const viewMoreHref = [
    generatePath(CREDITS_LIST_PATH),
    filterModelToQueryString<CreditListItem, 'assetId'>([
      filterValueGroup(filterValueItem('assetId', autocompleteIncludeFilterValue([id]))),
    ]),
  ].join('?')

  // TODO(@remy): Remove `isProduction` check when enabling this feature on production
  const visible = !isProduction() && !isXs && swrCredits.data.items.length > 0

  return { swrCredits, sort, setSort, viewMoreHref, visible } as const
}
