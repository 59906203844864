import {
  useAssetAdvisors,
  useAssetListItem,
  useLegalEntities,
  useListedSecurityList,
  useSimilarAssets,
} from '@gain/api/app/hooks'
import { useIsBrowserExtension } from '@gain/modules/browser-extension'
import { Asset, AssetSource } from '@gain/rpc/app-model'
import { AssetOwnershipType, AssetProfileType } from '@gain/rpc/shared-model'
import { listFilter, listFilters } from '@gain/rpc/utils'
import { isDefined } from '@gain/utils/common'
import { useHasAssetDeals } from '@gain/utils/legal-entity'
import { useIsXs } from '@gain/utils/responsive'
import { useMemo } from 'react'

import { ProfileTab } from '../../common/profile-tab-bar'
import {
  ASSET_PAGE_ADVISORS_PATH,
  ASSET_PAGE_ASSESSMENT_PATH,
  ASSET_PAGE_BENCHMARKING_PATH,
  ASSET_PAGE_BUSINESS_PATH,
  ASSET_PAGE_FINANCIALS_PATH,
  ASSET_PAGE_MARKET_PATH,
  ASSET_PAGE_OWNERSHIP_PATH,
  ASSET_PAGE_SOURCES_PATH,
  ASSET_PAGE_SUMMARY_PATH,
  ASSET_PAGE_VALUATION_PATH,
  generateAssetPagePath,
  useAssetPageParams,
} from '../utils'
import { hasBusiness } from './asset-business'
import { shouldShowFinancialsTab } from './asset-financials'
import { useCreditFacilities } from './asset-ownership/asset-ownership.hooks'
import { hasFundingMetrics } from './asset-ownership/card-asset-funding/card-asset-funding'

export type AssetSourceTypeKey = keyof Pick<
  AssetSource,
  'financials' | 'business' | 'background' | 'market'
>

export function useAssetSources(
  sources: AssetSource[] | undefined | null,
  ...types: AssetSourceTypeKey[]
) {
  return useMemo(() => {
    if (!isDefined(sources)) {
      return []
    }

    return sources.filter((source) => types.filter((type) => source[type]).length > 0)
  }, [sources, types])
}

function useRelevantEntities(asset?: Asset) {
  const entityIds = asset?.legalEntities.reduce((acc, current) => {
    if (!current.financials) {
      return acc
    }
    return acc.concat(current.legalEntityId)
  }, new Array<number>())

  return useLegalEntities(entityIds || [])
}

export function useHasAssessment(asset?: Asset) {
  const entities = useRelevantEntities(asset)

  if (asset?.profileType !== AssetProfileType.Automated) {
    return true
  } else if (entities.data.some((item) => !!item.rating?.overall)) {
    return true
  }

  return false
}

export function useAssetListedSecurities(asset?: Asset) {
  return useListedSecurityList(() => {
    if (!asset || asset.listedSecurities.length === 0) {
      return null
    }

    return {
      filter: listFilters(
        listFilter(
          'id',
          '=',
          asset.listedSecurities.map(({ listedSecurityId }) => listedSecurityId)
        )
      ),
      limit: asset.listedSecurities.length,
    }
  })
}

export function useHasRelevantEntityShareholders(asset?: Asset) {
  const entities = useRelevantEntities(asset)
  return entities.data.some((item) => !!item.legalEntityShareholders.length)
}

export function hasOwnership(
  asset: Asset,
  hasDeals: boolean,
  hasShareholders: boolean,
  hasFunding: boolean,
  hasCreditFacilities: boolean
): boolean {
  return (
    (asset.profileType === AssetProfileType.Automated &&
      (hasDeals || hasShareholders || hasFunding || hasCreditFacilities)) ||
    asset.profileType === AssetProfileType.Limited ||
    asset.profileType === AssetProfileType.Full ||
    !!asset.legalEntities.length
  )
}

export function useAssetPageTabs(asset?: Asset): ProfileTab[] {
  const isXs = useIsXs()
  const isBrowserExtension = useIsBrowserExtension()

  const params = useAssetPageParams()
  const swrAssetListItem = useAssetListItem(params.id)
  const hasAssessment = useHasAssessment(asset)
  const hasShareholders = useHasRelevantEntityShareholders(asset)
  const swrSimilarAssets = useSimilarAssets(params.id, 300)
  const hasDeals = useHasAssetDeals(params.id)
  const swrAssetAdvisors = useAssetAdvisors(params.id)
  const { visible: hasCreditFacilities } = useCreditFacilities(params.id)

  // Show the valuations tab for listed securities. For non-listed assets, show
  // the valuations tab only on larger screens (not on mobile/browser extension).
  let hasValuations = asset && asset.listedSecurities.length > 0
  if (!hasValuations) {
    hasValuations = asset?.generalInfo?.ownership !== AssetOwnershipType.Listed && !isXs
  }

  return useMemo(() => {
    if (!asset) {
      return []
    }

    const sources = asset.sources.length > 0
    const hasFunding = !!(swrAssetListItem.data && hasFundingMetrics(swrAssetListItem.data))
    const hasOwnershipTab = hasOwnership(
      asset,
      hasDeals,
      hasShareholders,
      hasFunding,
      hasCreditFacilities
    )
    const hasMarket = Boolean(
      swrSimilarAssets?.data?.items.length || asset.competitors.length || asset.market?.competition
    )
    const hasFinancialResults = shouldShowFinancialsTab(asset)
    const hasAdvisors = Boolean(swrAssetAdvisors.data?.length)

    let hasBusinessTab = hasBusiness(asset)
    // Only show business tab for automated profiles when there are other tabs
    // In the other case, meaning no tab, the asset-automated.component is rendered
    if (asset.profileType === AssetProfileType.Automated && hasBusinessTab) {
      hasBusinessTab =
        hasAssessment || hasFinancialResults || hasMarket || hasOwnershipTab || sources
    }

    const result = new Array<ProfileTab>()

    if (
      hasAssessment ||
      hasAdvisors ||
      hasFinancialResults ||
      hasBusinessTab ||
      hasMarket ||
      hasOwnershipTab ||
      sources
    ) {
      result.push({
        label: 'Summary',
        path: generateAssetPagePath(params, ASSET_PAGE_SUMMARY_PATH),
        value: 'summary',
      })
    }

    if (hasBusinessTab) {
      result.push({
        label: 'Business',
        path: generateAssetPagePath(params, ASSET_PAGE_BUSINESS_PATH),
        value: 'business',
      })
    }

    if (hasFinancialResults) {
      result.push({
        label: 'Financials',
        path: generateAssetPagePath(params, ASSET_PAGE_FINANCIALS_PATH),
        value: 'financials',
      })
    }

    if (hasOwnershipTab) {
      result.push({
        label: 'Ownership',
        path: generateAssetPagePath(params, ASSET_PAGE_OWNERSHIP_PATH),
        value: 'ownership',
      })
    }

    if (hasValuations) {
      result.push({
        label: 'Valuation',
        path: generateAssetPagePath(params, ASSET_PAGE_VALUATION_PATH),
        value: 'valuation',
      })
    }

    if (hasMarket) {
      result.push({
        label: 'Market',
        path: generateAssetPagePath(params, ASSET_PAGE_MARKET_PATH),
        value: 'market',
      })
    }

    if (!isBrowserExtension && !isXs) {
      result.push({
        label: 'Benchmarking',
        path: generateAssetPagePath(params, ASSET_PAGE_BENCHMARKING_PATH),
        value: 'benchmarking',
      })
    }

    if (hasAssessment) {
      result.push({
        label: 'Assessment',
        path: generateAssetPagePath(params, ASSET_PAGE_ASSESSMENT_PATH),
        value: 'assessment',
      })
    }

    if (hasAdvisors) {
      result.push({
        label: 'Advisors',
        path: generateAssetPagePath(params, ASSET_PAGE_ADVISORS_PATH),
        value: 'advisors',
      })
    }

    if (sources) {
      result.push({
        label: 'Sources',
        path: generateAssetPagePath(params, ASSET_PAGE_SOURCES_PATH),
        value: 'sources',
      })
    }

    return result
  }, [
    asset,
    swrAssetListItem.data,
    hasDeals,
    hasShareholders,
    hasCreditFacilities,
    swrSimilarAssets?.data?.items.length,
    swrAssetAdvisors.data?.length,
    hasAssessment,
    hasValuations,
    isBrowserExtension,
    isXs,
    params,
  ])
}
