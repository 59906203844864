import { LenderIcon } from '@gain/components/icons'
import { useOpenLink } from '@gain/utils/router'
import Chip from '@mui/material/Chip'
import { MouseEvent } from 'react'

import { generateLenderPagePath } from '../../routes/lender/lender-path'
import LenderTooltip from './lender-tooltip/lender-tooltip'

interface LenderChipProps {
  id: number
  name: string
}

export default function LenderChip({ id, name }: LenderChipProps) {
  const openLink = useOpenLink()

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()

    openLink(generateLenderPagePath(id, name), event)
  }

  return (
    <LenderTooltip id={id}>
      <Chip
        icon={<LenderIcon color={'inherit'} />}
        label={name}
        onClick={handleClick}
        sx={{ minWidth: 0 }}
        clickable
      />
    </LenderTooltip>
  )
}
