import { useLenderCreditsPerSubsectorDistribution } from '@gain/api/app/hooks'
import { CreditListItem, Lender, LenderCreditSubtypeCount } from '@gain/rpc/app-model'
import { useOpenLink } from '@gain/utils/router'
import { formatSector, formatSubsector, getSectorForSubsector } from '@gain/utils/sector'
import { MouseEvent } from 'react'

import Card, { CardContentEmpty, CardHeader } from '../../../common/card/card'
import { chartColorSetGreen } from '../../../common/chart/chart-colors'
import TableValueDistribution, {
  TableValueDistributionConfig,
  useValueDistributionRows,
  ValueDistributionRow,
} from '../../../common/table-value-distribution'
import {
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../../features/filter/filter-bar'
import { generateLenderPagePath, LENDER_CREDITS_PATH } from '../lender-path'
import { MIN_VALUE_DISTRIBUTION_CARD_HEIGHT } from './route-summary-utils'

const CREDITS_PER_SECTOR_CONFIG: TableValueDistributionConfig<LenderCreditSubtypeCount> = {
  getValue: (credit) => credit.subsector,
  getGroupBy: (key) => getSectorForSubsector(key) || key,
  formatValue: (value) => formatSubsector(value) || value,
  formatGroup: (value) => formatSector(value) || value,
  getIncrementBy: (item) => item.count,
  colorSet: chartColorSetGreen.slice().reverse(),
}

interface CreditsPerSectorCardProps {
  lender: Lender
  credits: CreditListItem[]
}

export default function CreditsPerSectorCard({ lender, credits }: CreditsPerSectorCardProps) {
  const bla = useLenderCreditsPerSubsectorDistribution(lender.id)
  const rows = useValueDistributionRows(bla.data || [], CREDITS_PER_SECTOR_CONFIG)

  const openLink = useOpenLink()
  const creditsTabPath = generateLenderPagePath(lender.id, lender.name, LENDER_CREDITS_PATH)

  const handleRowClick = (row: ValueDistributionRow, event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    const subsectors = row.values ? row.values.map((item) => item.type) : row.type
    const path = [
      creditsTabPath,
      filterModelToQueryString<CreditListItem, 'assetSubsector'>([
        filterValueGroup(filterValueItem('assetSubsector', subsectors)),
      ]),
    ].join('?')

    openLink(path, event)
  }

  return (
    <Card
      href={rows.length > 0 ? creditsTabPath : undefined}
      sx={{ minHeight: MIN_VALUE_DISTRIBUTION_CARD_HEIGHT }}
      fullHeight>
      <CardHeader title={'Credits per sector'} />
      {rows.length === 0 ? (
        <CardContentEmpty message={'No data available'} />
      ) : (
        <TableValueDistribution
          itemLabel={'Sector'}
          onRowClick={handleRowClick}
          rows={rows}
          shareLabel={'Credits'}
          disableExpand
        />
      )}
    </Card>
  )
}
