import { CreditListItem } from '@gain/rpc/app-model'
import { List } from '@gain/rpc/list-model'
import {
  CREDIT_REFERENCE_RATE_OPTIONS,
  CREDIT_TYPE_OPTIONS,
  getLabelFromOption,
} from '@gain/rpc/shared-model'
import { formatDate } from '@gain/utils/date'
import { formatBps } from '@gain/utils/number'
import Stack from '@mui/material/Stack'

import { UseAppListSwrResult } from '../../../../../libs/api/swr/src/use-app-list-swr'
import Card, { CardHeader } from '../../common/card/card'
import ViewMoreButton from '../../common/view-more-button'
import { createVirtualTableColumns, VirtualSort } from '../../common/virtual-table'
import VirtualTable from '../../common/virtual-table/virtual-table'
import FinancialValue from '../financial/financial-value'
import LendersCell from '../lender/lenders-cell'

const columns = createVirtualTableColumns<CreditListItem>(
  {
    field: 'lenders',
    headerName: 'Lender',
    width: 196,
    sticky: true,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => (
      <LendersCell
        lenders={row.lenders}
        variant={'content-link'}
      />
    ),
  },
  {
    headerName: 'Credit type',
    field: 'type',
    width: 208,
    valueFormatter: ({ value }) => getLabelFromOption(CREDIT_TYPE_OPTIONS, value) || '-',
  },
  {
    field: 'debtQuantumEur',
    headerName: 'Debt quantum',
    align: 'right',
    width: 152,
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
  {
    field: 'issuedAt',
    headerName: 'Issuance date',
    align: 'right',
    width: 144,
    valueFormatter: ({ value }) => formatDate(value, { format: 'monthYear' }),
  },
  {
    field: 'referenceRate',
    headerName: 'Reference rate',
    align: 'left',
    width: 144,
    valueFormatter: ({ value }) => getLabelFromOption(CREDIT_REFERENCE_RATE_OPTIONS, value, '-'),
  },
  {
    field: 'couponBps',
    headerName: 'Coupon',
    align: 'right',
    width: 120,
    valueFormatter: ({ value }) => formatBps(value),
  },
  {
    field: 'maturedAt',
    headerName: 'Maturity date',
    align: 'right',
    width: 144,
    valueFormatter: ({ value }) => formatDate(value, { format: 'monthYear' }),
  }
)

export interface CardCreditFacilitiesProps {
  swrCredits: UseAppListSwrResult<List<CreditListItem>>
  sort: VirtualSort<CreditListItem>[]
  setSort: (sort: VirtualSort<CreditListItem>[]) => void
  viewMoreHref?: string
}

export default function CardCreditFacilities({
  swrCredits,
  sort,
  setSort,
  viewMoreHref,
}: CardCreditFacilitiesProps) {
  const rest = swrCredits.data.counts.filtered - swrCredits.data.items.length

  return (
    <Card>
      <CardHeader title={'Credit facilities'} />
      <Stack>
        <VirtualTable
          columns={columns}
          onSort={setSort}
          RowComponentProps={{
            hover: false,
          }}
          rows={swrCredits.data.items}
          sort={sort}
          variant={'inline'}
          disablePaddingStart
        />
        {viewMoreHref && rest > 0 && (
          <ViewMoreButton
            amount={rest}
            href={viewMoreHref}
            variant={'chevron-right'}
          />
        )}
      </Stack>
    </Card>
  )
}
