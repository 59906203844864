import Flag from '@gain/components/flag'
import { CompanyIcon } from '@gain/components/icons'
import { CreditListItem } from '@gain/rpc/app-model'
import { CREDIT_REFERENCE_RATE_OPTIONS, getLabelFromOption } from '@gain/rpc/shared-model'
import { formatDate } from '@gain/utils/date'
import { formatBps } from '@gain/utils/number'

import ContentLink from '../../../common/content-link'
import { ColumnConfig, createVirtualTableColumns } from '../../../common/virtual-table'
import {
  NameVirtualTableCell,
  NameVirtualTableHeaderCell,
} from '../../../common/virtual-table/cells'
import { generateAssetPagePath } from '../../../routes/utils'
import AssetTooltip from '../../asset/asset-tooltip'
import FinancialValue from '../../financial/financial-value'
import LendersCell from '../../lender/lenders-cell'
import CreditTypeColumn from './credit-type-column'

export const creditTableColumns: ColumnConfig<CreditListItem>[] =
  createVirtualTableColumns<CreditListItem>(
    {
      field: 'assetName',
      headerName: 'Company',
      align: 'left',
      width: 312,
      sticky: true,
      flex: 1,
      renderCell: ({ row }) => {
        if (row.assetId === null) {
          if (row.assetName === null) {
            return '-'
          }

          return (
            <NameVirtualTableCell
              defaultIcon={<CompanyIcon />}
              logoFileUrl={null}
              name={row.assetName}
            />
          )
        }

        return (
          <NameVirtualTableCell
            defaultIcon={<CompanyIcon />}
            description={row.assetDescription}
            logoFileUrl={row.assetLogoFileUrl}
            name={
              <AssetTooltip id={row.assetId}>
                <ContentLink
                  href={generateAssetPagePath({
                    id: row.assetId,
                    name: row.assetName,
                  })}
                  label={row.assetName}
                  labelTypographyProps={{
                    color: 'textPrimary',
                    variant: 'subtitle2',
                    noWrap: true,
                  }}
                />
              </AssetTooltip>
            }
          />
        )
      },
      renderHeader: NameVirtualTableHeaderCell,
    },
    {
      field: 'type',
      headerName: 'Credit type',
      align: 'left',
      width: 208,
      renderCell: ({ value }) => <CreditTypeColumn type={value} />,
    },
    {
      field: 'debtQuantumEur',
      headerName: 'Debt quantum',
      align: 'right',
      width: 152,
      renderCell: ({ value }) => <FinancialValue amount={value} />,
    },
    {
      field: 'lenders',
      headerName: 'Lenders',
      align: 'left',
      width: 332,
      renderCell: ({ row }) => (
        <LendersCell
          lenders={row.lenders}
          variant={'chip'}
        />
      ),
    },
    {
      field: 'issuedAt',
      headerName: 'Issuance date',
      align: 'right',
      width: 144,
      valueFormatter: ({ value }) => formatDate(value, { format: 'monthYear' }),
    },
    {
      field: 'referenceRate',
      headerName: 'Reference rate',
      align: 'left',
      width: 152,
      valueFormatter: ({ value }) => getLabelFromOption(CREDIT_REFERENCE_RATE_OPTIONS, value, '-'),
    },
    {
      field: 'couponBps',
      headerName: 'Coupon',
      align: 'left',
      width: 120,
      valueFormatter: ({ value }) => formatBps(value),
    },
    {
      field: 'maturedAt',
      headerName: 'Maturity date',
      align: 'right',
      width: 144,
      valueFormatter: ({ value }) => formatDate(value, { format: 'monthYear' }),
    },
    {
      field: 'assetHqCountryCode',
      headerName: 'Target HQ',
      align: 'center',
      width: 128,
      renderCell: ({ value }) => {
        if (!value) {
          return '-'
        }

        return <Flag code={value} />
      },
    }
  )
