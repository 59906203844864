import OverflowContainer from '@gain/components/overflow-container'
import { LinkedLender } from '@gain/rpc/app-model'

import LenderChip from './lender-chip'
import LenderContentLink from './lender-content-link'

type Variant = 'chip' | 'content-link'

interface RenderLenderProps {
  variant: Variant
  lender: LinkedLender
}

function RenderLender({ variant, lender }: RenderLenderProps) {
  if (variant === 'chip') {
    return (
      <LenderChip
        id={lender.id}
        name={lender.name}
      />
    )
  }

  return (
    <LenderContentLink
      id={lender.id}
      logoFileUrl={lender.logoFileUrl}
      name={lender.name}
    />
  )
}

interface LendersCellProps {
  lenders: LinkedLender[]
  variant: Variant
}

export default function LendersCell({ lenders, variant }: LendersCellProps) {
  if (!lenders.length) {
    return <>-</>
  }

  return (
    <OverflowContainer
      chipSize={'medium'}
      lineHeight={24}
      maxLines={1}
      disableObserver>
      {lenders.map((lender) => (
        <RenderLender
          key={lender.id}
          lender={lender}
          variant={variant}
        />
      ))}
    </OverflowContainer>
  )
}
