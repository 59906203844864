import { useRpcClient } from '@gain/api/swr'
import Snackbar from '@gain/components/snackbar'
import { CustomAssetList } from '@gain/rpc/app-model'
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'

import { useCheckBookmarksListError } from './bookmarks-hooks'
import { useBookmarkListAddAssets } from './bookmarks-list-add-assets-hook'
import { useMutateBookmarksList } from './bookmarks-list-hooks'

const SNACKBAR_KEY = 'bookmarks-list-remove-assets'

function useShowSnackbar() {
  const { enqueueSnackbar } = useSnackbar()
  const addAssets = useBookmarkListAddAssets()

  return useCallback(
    (list: CustomAssetList, assetIds: number[]) => {
      const subject = assetIds.length === 1 ? 'Company' : `${assetIds.length} companies`
      enqueueSnackbar(undefined, {
        key: SNACKBAR_KEY,
        content: () => (
          <Snackbar
            action={{
              title: 'Undo',
              onClick: () => addAssets(list, assetIds),
            }}
            id={SNACKBAR_KEY}
            message={`${subject} removed from “${list.title}”`}
          />
        ),
        preventDuplicate: true,
      })
    },
    [addAssets, enqueueSnackbar]
  )
}

export function useBookmarksListRemoveAssets() {
  const mutateList = useMutateBookmarksList()
  const checkListError = useCheckBookmarksListError()
  const fetcher = useRpcClient()
  const showSnackbar = useShowSnackbar()

  return useCallback(
    async (list: CustomAssetList, assetIds: number[], disableSnackbar = false) => {
      try {
        const updatedList = await fetcher({
          method: 'lists.customAssetListRemoveAssets',
          params: { id: list.id, assetIds },
        })

        if (!disableSnackbar) {
          showSnackbar(list, assetIds)
        }

        mutateList(list.id, () => updatedList)
      } catch (err) {
        checkListError(err)
      }
    },
    [checkListError, mutateList, fetcher, showSnackbar]
  )
}
